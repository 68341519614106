$(document).on('click', '#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton', RandomProductToShoppingCart);
var RandomProductsArray = new Array();
$(document).ready(function(){
	getPrincipalSlides();
    getStarProducts();
    getBestSellerProducts();
    getNewProducts();
    getRandomProducts();
    GetDiscountNewStarProducts();
});
function getPrincipalSlides(){
    $('#PrincipalSlides #PrincipalSlidesContainer #PrincipalSlidesList').empty();
    $('#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList').empty();
    PostMethodFunction('/WebPagePetitions/Slides/Get', {}, null, ShowPrincipalSlideProducts, null);
}
function ShowPrincipalSlideProducts(Response){
    var AppendContentSlides = '';
    var AppendContentSelectors = '';
    var CounterSlides = 0;
	if($(window).width()>700){
		$.each(Response, function(index, Slide){
            if(CounterSlides == 0){
                CurrentSuperSlide = Slide.id;
                AppendContentSlides += '<li class="PrincipalSlidesListItem PrincipalSlidesListItemCurrentVisible" value="'+Slide.id+'" index="'+Slide.index+'" action="'+Slide.action+'">';
            
            }else{
                AppendContentSlides += '<li class="PrincipalSlidesListItem PrincipalSlidesListItemHidden" value="'+Slide.id+'" index="'+Slide.index+'" action="'+Slide.action+'">';
            }
            /*if(Slide.type == 0){
                AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductTextContainer" value="'+Slide.action+'">';
                    AppendContentSlides += '<h1 class="PrincipalSlidesListItem_ProductTextTitle">'+Slide.text+'</h1>';
                    if(Slide.sub_text != null){
                        AppendContentSlides += '<h2 class="PrincipalSlidesListItem_ProductTextSubTitle">'+Slide.sub_text+'</h2>';
                    }
                    if(Slide.brief != null){
                        AppendContentSlides += '<p class="PrincipalSlidesListItem_ProductTextSubParagraph">'+String(Slide.brief).replace(/(?:\r\n|\r|\n)/g, '<br>')+'</p>';
                    }
                    AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductButtonsContainer" value="'+Slide.action+'">';
                        AppendContentSlides += '<button class="PrincipalSlidesListItem_ProductButtonView">Ver producto</button>';
                    AppendContentSlides += '</div>';
                AppendContentSlides += '</div>';
               
                AppendContentSlides += '<img src="/images/products/principal/'+Slide.slide_id+'.'+Slide.img+'" alt="'+Slide.text+'" class="PrincipalSlidesListItem_ProductImage"  value="'+Slide.action+'">';
            }else{*/
                /*AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductTextContainer" value="'+Slide.action+'">';
                    AppendContentSlides += '<h1 class="PrincipalSlidesListItem_ProductTextTitle">'+Slide.text+'</h1>';
                    if(Slide.sub_text != null){
                        AppendContentSlides += '<h2 class="PrincipalSlidesListItem_ProductTextSubTitle">'+Slide.sub_text+'</h2>';
                    }
                    if(Slide.brief != null){
                        AppendContentSlides += '<p class="PrincipalSlidesListItem_ProductTextSubParagraph">'+String(Slide.brief).replace(/(?:\r\n|\r|\n)/g, '<br>')+'</p>';
                    }
                    AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductButtonsContainer" value="'+Slide.action+'">';
                        AppendContentSlides += '<button class="PrincipalSlidesListItem_ProductButtonView">Ver blog</button>';
                    AppendContentSlides += '</div>';
                AppendContentSlides += '</div>';*/
                AppendContentSlides += '<img src="" alt="'+Slide.text+'" class="PrincipalSlidesListItem_ProductImageHidden"  value="'+Slide.action+'" onclick="location.href=\''+Slide.action+'\'">';
                AppendContentSlides += '<input type="hidden" class="PrincipalSlidesListItem_ProductImage" value="/images/blog/'+Slide.slide_id+'.'+Slide.img+'">';
            //}
            AppendContentSlides += '</li>';
            CounterSlides++;
            if(CounterSlides==Response.length){
                    AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="0" back="'+(Response.length-2)+'" index="'+(CounterSlides-1)+'"></li>';
            }else{
                if(CounterSlides==1){
                   ///////////////////////////////////////////
                    AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="'+CounterSlides+'" back="'+(Response.length-1)+'" index="'+(CounterSlides-1)+'"></li>';
                    ///////////////////////////////////////////
                }else{
                    ///////////////////////////////////////////
                    AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="'+CounterSlides+'" back="'+(CounterSlides-2)+'" index="'+(CounterSlides-1)+'"></li>';
                    ///////////////////////////////////////////
                }
            }
            
        });
	}else{
        var maxSlides = 6;
        $.each(Response, function(index, Slide){
            if(index<maxSlides){
                if(CounterSlides == 0){
                    CurrentSuperSlide = Slide.id;
                    AppendContentSlides += '<li class="PrincipalSlidesListItem PrincipalSlidesListItemCurrentVisible" value="'+Slide.id+'" index="'+Slide.index+'" action="'+Slide.action+'">';
                
                }else{
                    AppendContentSlides += '<li class="PrincipalSlidesListItem PrincipalSlidesListItemHidden" value="'+Slide.id+'" index="'+Slide.index+'" action="'+Slide.action+'">';
                }
                /*if(Slide.type == 0){
                    AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductTextContainer" value="'+Slide.action+'">';
                        AppendContentSlides += '<h1 class="PrincipalSlidesListItem_ProductTextTitle">'+Slide.text+'</h1>';
                        if(Slide.sub_text != null){
                            AppendContentSlides += '<h2 class="PrincipalSlidesListItem_ProductTextSubTitle">'+Slide.sub_text+'</h2>';
                        }
                        if(Slide.brief != null){
                            AppendContentSlides += '<p class="PrincipalSlidesListItem_ProductTextSubParagraph">'+String(Slide.brief).replace(/(?:\r\n|\r|\n)/g, '<br>')+'</p>';
                        }
                        AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductButtonsContainer" value="'+Slide.action+'">';
                            AppendContentSlides += '<button class="PrincipalSlidesListItem_ProductButtonView">Ver producto</button>';
                        AppendContentSlides += '</div>';
                    AppendContentSlides += '</div>';
                
                    AppendContentSlides += '<img src="/images/products/principal/'+Slide.slide_id+'.'+Slide.img+'" alt="'+Slide.text+'" class="PrincipalSlidesListItem_ProductImage"  value="'+Slide.action+'">';
                }else{*/
                    AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductTextContainer" value="'+Slide.action+'">';
                        AppendContentSlides += '<h1 class="PrincipalSlidesListItem_ProductTextTitle">'+Slide.text+'</h1>';
                        if(Slide.sub_text != null){
                            AppendContentSlides += '<h2 class="PrincipalSlidesListItem_ProductTextSubTitle">'+Slide.sub_text+'</h2>';
                        }
                        if(Slide.brief != null){
                            AppendContentSlides += '<p class="PrincipalSlidesListItem_ProductTextSubParagraph">'+String(Slide.brief).replace(/(?:\r\n|\r|\n)/g, '<br>')+'</p>';
                        }
                        AppendContentSlides += '<div class="PrincipalSlidesListItem_ProductButtonsContainer" value="'+Slide.action+'">';
                            AppendContentSlides += '<button class="PrincipalSlidesListItem_ProductButtonView">Ver blog</button>';
                        AppendContentSlides += '</div>';
                    AppendContentSlides += '</div>';
                     AppendContentSlides += '<img src="" alt="'+Slide.text+'" class="PrincipalSlidesListItem_ProductImageHidden"  value="'+Slide.action+'">';
                     AppendContentSlides += '<input type="hidden" class="PrincipalSlidesListItem_ProductImage" value="/images/blog/'+Slide.slide_id+'.'+Slide.img+'">';
                //}
                AppendContentSlides += '</li>';
                CounterSlides++;
                if(CounterSlides==maxSlides){
                        AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="0" back="'+(maxSlides-2)+'" index="'+(CounterSlides-1)+'"></li>';
                }else{
                    if(CounterSlides==1){
                    ///////////////////////////////////////////
                        AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="'+CounterSlides+'" back="'+(maxSlides-1)+'" index="'+(CounterSlides-1)+'"></li>';
                        ///////////////////////////////////////////
                    }else{
                        ///////////////////////////////////////////
                        AppendContentSelectors += '<li class="SelectorPrincipalSlidesListItem" value="'+Slide.id+'" next="'+CounterSlides+'" back="'+(CounterSlides-2)+'" index="'+(CounterSlides-1)+'"></li>';
                        ///////////////////////////////////////////
                    }
                }
            }
            
        });
    }
	$('#PrincipalSlides #PrincipalSlidesContainer #PrincipalSlidesList').append(AppendContentSlides);
    $('#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList').append(AppendContentSelectors);
    ShowCurrentSuperSlide();
}
function getStarProducts(){
    List = $('#StarProductsList');
    List.empty();
    PostMethodFunction('/WebPagePetitions/Products/GetStarProducts', {}, null, GeneralProductsShower, null, List);
}
function getBestSellerProducts(){
    var List = $('#TopSellerProductsList');
    List.empty();
    PostMethodFunction('/WebPagePetitions/Products/GetBestSellerProducts', {}, null, GeneralProductsShower, null, List);
}
function getNewProducts(){
    List = $('#NewProductsList');
    List.empty();
    PostMethodFunction('/WebPagePetitions/Products/GetNewestProducts', {}, null, GeneralProductsShower, null, List);
}
function getRandomProducts(){
    PostMethodFunction('/WebPagePetitions/Products/GetRandomProducts', {}, null, ShowRandomProducts, null);
}
function ShowRandomProducts(response){
    RandomProductsArray = response;
    if(response.length > 0){
        var price = response[0].price;
        if(response[0].general_discount != null){
            price = (price * (1-(response[0].general_discount/100)))*(1+(response[0].tax/100));
        }else{
            price = response[0].price_wt;
        }
        $('#IndexContainer #RandomProductsContainer .RandomProductSubContainer').empty();
        var AppendContent = '';
        AppendContent += '<div class="RandomProductsCenteredContainer scrollAutomatic" value="'+response[0].index+'">';
            AppendContent += '<div class="RandomProductSubContainer_Text">';
                AppendContent += '<div class="RandomProductSubContainer_TextProductNameContainer">';
                    AppendContent += '<h1 class="RandomProductsSubContainer_Name">'+response[0].name+'</h1>';
                    if(response[0].sub_name != null){
                        AppendContent += '<h2 class="RandomProductsSubContainer_SubName">'+response[0].sub_name+'</h2>';
                    }
                    if(response[0].manage_size == 1){
                        AppendContent += '<h3 class="RandomProductsSubContainer_Size">Talla '+response[0].size+'</h3>';
                    }else if(response[0].manage_size == 1){
                        AppendContent += '<h3 class="RandomProductsSubContainer_Size">por '+response[0].size+'</h3>';
                    }
                AppendContent += '</div>';
                AppendContent += '<div class="RandomProductSubContainer_TextProductButton">';
                    AppendContent += '<button class="RandomProductSubContainer_TextProductBuyButton">';
                        AppendContent += '<img width="20" height="19" style="height:auto;" src="/images/shopping_cart_white.webp" alt="buy_icon" class="RandomProductSubContainer_TextProductBuyButtonIcon">';
                        AppendContent += '<p class="RandomProductSubContainer_TextProductBuyButtonText">Comprar</p>';
                    AppendContent += '</button>';
                    AppendContent += '<p class="RandomProductSubContainer_TextProductPrice">$'+Number(Math.round(price)).toLocaleString()+'</p>';
                    AppendContent += '</div>';
            AppendContent += '</div>';
            AppendContent += '<img width="1" height="1" style="height:auto;" src="/images/products/principal/'+response[0].id+"."+response[0].general_img+'" alt="Product image" class="RandomProductSubContainer_Image"></img>';
        AppendContent += '</div>';
        $('#IndexContainer #RandomProductsContainer #RandomProductSubContainer_1').append(AppendContent);
        /*--------------------------------------------------------*/
        /*--------------------------------------------------------*/
        /*--------------------------------------------------------*/
        if(response.length > 1){
            var price = response[1].price;
            if(response[1].general_discount != null){
                price = (price * (1-(response[1].general_discount/100)))*(1+(response[1].tax/100));
            }else{
                price = response[0].price_wt;
            }
            AppendContent = '<div class="RandomProductsCenteredContainer scrollAutomatic" value="'+response[1].index+'">';
                AppendContent += '<div class="RandomProductSubContainer_Text">';
                    AppendContent += '<div class="RandomProductSubContainer_TextProductNameContainer">';
                        AppendContent += '<h1 class="RandomProductsSubContainer_Name">'+response[1].name+'</h1>';
                        if(response[1].sub_name != null){
                            AppendContent += '<h2 class="RandomProductsSubContainer_SubName">'+response[1].sub_name+'</h2>';
                        }
                        if(response[1].manage_size == 1){
                            AppendContent += '<h3 class="RandomProductsSubContainer_Size">Talla '+response[1].size+'</h3>';
                        }else if(response[1].manage_size == 1){
                            AppendContent += '<h3 class="RandomProductsSubContainer_Size">por '+response[1].size+'</h3>';
                        }
                    AppendContent += '</div>';
                    AppendContent += '<div class="RandomProductSubContainer_TextProductButton">';
                        AppendContent += '<button class="RandomProductSubContainer_TextProductBuyButton">';
                            AppendContent += '<img width="20" height="19" style="height:auto;" src="/images/shopping_cart_white.webp" alt="buy_icon" class="RandomProductSubContainer_TextProductBuyButtonIcon">';
                            AppendContent += '<p class="RandomProductSubContainer_TextProductBuyButtonText">Comprar</p>';
                        AppendContent += '</button>';
                        AppendContent += '<p class="RandomProductSubContainer_TextProductPrice">$'+Number(Math.round(price)).toLocaleString()+'</p>';
                        AppendContent += '</div>';
                AppendContent += '</div>';
                AppendContent += '<img width="1" height="1" style="height:auto;" src="/images/products/principal/'+response[1].id+"."+response[1].general_img+'" alt="Product image" class="RandomProductSubContainer_Image"></img>';
            AppendContent += '</div>';
            $('#IndexContainer #RandomProductsContainer #RandomProductSubContainer_2').append(AppendContent);
        }else{
            $('#IndexContainer #RandomProductsContainer #RandomProductSubContainer_2').remove();
        }
    }else{
        $('#IndexContainer #RandomProductsContainer').remove();
    }
}
function GetDiscountNewStarProducts(){
    PostMethodFunction('/WebPagePetitions/Products/GetDiscountNewStarProducts', {}, null, ShowGetDiscountNewStarProducts, null);
}
function ShowGetDiscountNewStarProducts(response){
    
    if(response.discount != null){
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_SpecialOfert').empty();
        var AppendContent = '';
        AppendContent += '<div class="SegmentProductSubContainerCentered">';
            AppendContent += '<img src="/images/products/principal/'+response.discount.id+'.'+response.discount.general_img+'" alt="product_image" class="SegmentProductSubContainer_Image">';
            AppendContent += '<div class="SegmentProductSubContainer_TextContainer">';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextTitle">Oferta especial</h1>';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextProductTitle">'+response.discount.name+'</h1>';
                if(response.discount.general_discount!= null && response.discount.general_discount != 0){
                    AppendContent += '<p class="SegmentProductSubContainer_TextPrice">'+response.discount.general_discount+'% OFF</p>';
                }
                AppendContent += '<a href="/Product/'+response.discount.index+'" target="_blank" class="SegmentProductSubContainer_TextViewMoreLink">Ver más ></a>';
            AppendContent += '</div>';
        AppendContent += '</div>';
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_SpecialOfert').append(AppendContent);
    }else{
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_SpecialOfert').remove();
    }
    /*---------------------------------------------*/
    if(response.new != null){
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_NewProduct').empty();
        var AppendContent = '';
        AppendContent += '<div class="SegmentProductSubContainerCentered">';
            AppendContent += '<img src="/images/products/principal/'+response.new.id+'.'+response.new.general_img+'" alt="product_image" class="SegmentProductSubContainer_Image">';
            AppendContent += '<div class="SegmentProductSubContainer_TextContainer">';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextTitle">Producto nuevo</h1>';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextProductTitle">'+response.new.name+'</h1>';
                AppendContent += '<p class="SegmentProductSubContainer_TextPrice">$'+Number(response.new.price).toLocaleString()+'</p>';
                AppendContent += '<a href="/Product/'+response.new.index+'" target="_blank" class="SegmentProductSubContainer_TextViewMoreLink">Ver más ></a>';
            AppendContent += '</div>';
        AppendContent += '</div>';
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_NewProduct').append(AppendContent);
    }else{
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_NewProduct').remove();
    }
    /*---------------------------------------------*/
    if(response.star != null){
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_StarProduct').empty();
        var AppendContent = '';
        AppendContent += '<div class="SegmentProductSubContainerCentered">';
            AppendContent += '<img src="/images/products/principal/'+response.star.id+'.'+response.star.general_img+'" alt="product_image" class="SegmentProductSubContainer_Image">';
            AppendContent += '<div class="SegmentProductSubContainer_TextContainer">';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextTitle">Producto destacado</h1>';
                AppendContent += '<h1 class="SegmentProductSubContainer_TextProductTitle">'+response.star.name+'</h1>';
                AppendContent += '<p class="SegmentProductSubContainer_TextPrice">$'+Number(response.star.price).toLocaleString()+'</p>';
                AppendContent += '<a href="/Product/'+response.star.index+'" target="_blank" class="SegmentProductSubContainer_TextViewMoreLink">Ver más ></a>';
            AppendContent += '</div>';
        AppendContent += '</div>';
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_StarProduct').append(AppendContent);
    }else{
        $('#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_StarProduct').remove();
    
    }
}
function RandomProductToShoppingCart(event){
    event.preventDefault();
    event.stopPropagation();
	var Index = $(this).parent().parent().parent().parent().attr('index');
    var current_size_id = null;
    var current_color_value = null;
    var random_product_id = RandomProductsArray[Index].id;
    if(RandomProductsArray[Index].manage_size != 0){
        current_size_id = RandomProductsArray[Index].size_id;
    }
    var quantity = 1;
    AddShoppingCartItem(random_product_id, current_size_id, current_color_value, quantity);
}